import React from 'react';
import { useState } from 'react';
import './Contact.scss';
import {ImLinkedin2, ImGithub, ImInstagram} from 'react-icons/im';
import { Link } from 'react-router-dom';


const Contact = () => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();


    // Clear form inputs
    setName('');
    setEmail('');
    setMessage('');
  };


  return (
    <div className="contact">
      <div className="top">
        <h1>Stay in touch</h1>
      </div>
      <div className="bottom">
        <div className="left">
          <form className="contact-form" onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Your Name"
              value={name}
              onChange={handleNameChange}
              required
            />
            <input
              type="email"
              placeholder="Your Email Address"
              value={email}
              onChange={handleEmailChange}
              required
            />
            <textarea
              placeholder="Your Message"
              rows={5}
              value={message}
              onChange={handleMessageChange}
              required
            ></textarea>
            <button type="submit">Send Message</button>
          </form>
        </div>
        <div className="right">
          <p>
            Please feel free to call or email us, or use our contact form to get in touch with us.
          </p>
          <p>We look forward to hearing from you!</p>
          <div className="icons">
            <Link to='https://www.linkedin.com/in/neeraj-labba-10012002/' className='link'>
                <ImLinkedin2/>
            </Link>
            <Link to='https://github.com/JerryXO' className='link'>
                <ImGithub/>
            </Link>
            <Link to='https://www.instagram.com/_neeraj_xo/' className='link'>
                <ImInstagram/>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact