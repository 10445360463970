import React from 'react'
import { Link } from 'react-router-dom'
import './Navbar.scss';

const Navbar = () => {
  return (
    <div className="navbar">
        <div className="wrapper">
            <div className="left">
                <div className="item">
                    <Link className='link' to='/'>Home</Link>
                </div>
                <div className="item">
                    <Link className='link' to='/about'>About</Link>
                </div>
            </div>
            <div className="centre">
                <div className="item">
                    <Link className='link' to='/'>
                        <span className='logo'> XO PORTFOLIO</span>
                    </Link>
                </div>
            </div>
            <div className="right">
                <div className="item">
                    <Link className='link' to='/projects'>Projects</Link>
                </div>
                <div className="item">
                    <Link className='link' to='/contact'>Contact</Link>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Navbar